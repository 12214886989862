export const statusChecker = (status) => {
  if (status == 0) {
    return "/login";
  }
  if (status == 1) {
    return "/verify-otp";
  }
  if (status == 2) {
    return "/dashboard";
  }
};
