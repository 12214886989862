import requests from "./http";

class FAQService {
  getItems() {
    return requests.get(`/getfaq`);
  }
  getSingleItem(id) {
    return requests.get(`/getSinglefaq/${id}`);
  }
  getProdItem(id) {
    return requests.get(`/getfaq/prod/${id}`);
  }
  getSubProdItem(id) {
    return requests.get(`/getfaq/sub-prod/${id}`);
  }
}

export default new FAQService();
