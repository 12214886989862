import BillingIcon from "../assets/images/billing.png";
import ShippingIcon from "../assets/images/shipping-desk.png";
import ProductIcon from "../assets/images/products.png";

const faqMenus = [
    { path: "/faq/billing", title: "Billing", img:BillingIcon },
    { path: "/faq/shipping", title: "Shipping", img:ShippingIcon },
    { path: "/faq/product", title: "Product related", img:ProductIcon },
  ];

export default Object.freeze(faqMenus);