import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context";
import { useCallback, useEffect, useState } from "react";
import productService from "../../../services/FeatureProdservice";
import useFetch from "../../../hooks/useFetch";
import { isTokenValid } from "../../../utils/isTokenValid";
import useImageFetch from "../../../hooks/useImageFetch";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DeleteSingleOrder from "../../../components/DelSingleOrder";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import OrderService from "../../../services/OrderService";
import useFaqFetch from "../../../hooks/faqFetch";
import Loading from "../../../assets/images/loader.gif";

const ReviseConfirmd = () => {
  const { id } = useParams();
  const { dispatch } = useAuth();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [similarPrd, setSimilarPrd] = useState([]);
  const [prd, setPrd] = useState(false);
  const [ip, setIP] = useState("");
  const [smProdLoading, setSmProdLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    // const res = await axios.get("https://geolocation-db.com/json/");
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    (() =>{   window.scrollTo({top: 0, behavior: 'smooth'});})();
    getData();
  }, []);

  useEffect(() => {
    try {
      const token = JSON.stringify(localStorage.getItem("token"));
      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  const getSingleProduct = useCallback(() => {
    return OrderService.getSingleItem(id);
  }, [id]);

  // const getSimilarProducts = useCallback(() => {
  //   return productService.getItems(id);
  // }, [id]);

  useEffect(() => {
    const image = JSON.stringify(JSON.parse(localStorage.getItem("image")));
    const name = JSON.parse(JSON.stringify(localStorage.getItem("name")));
    const mainImage = `${localStorage?.getItem("mainImage")}`;

    setProduct({ name, image, mainImage });
  }, []);

  // fetching similar products
  useEffect(() => {
    setSmProdLoading(true);
    const getSimilarProducts = async (data) => {
      try {
        const response = await productService.getItems(data, {
          name: product?.name,
        });

        const uniqueItems = response.data.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.product_name === item.product_name)
        );
        // console.log("*", uniqueItems);
        setSimilarPrd(uniqueItems);
        // response.data.filter((item))
        setSmProdLoading(false);
      } catch (error) {
        setSmProdLoading(false);
        // console.log("error: ", error);
      }
    };
    if (productDetails) {
      getSimilarProducts(productDetails.product_id);
    }
  }, [productDetails]);

  const { data, isSuccess, error, isError, isLoading } =
    useFetch(getSingleProduct);

  const { imageData } = useImageFetch();

  useEffect(() => {
    const setProductDetail = (param) => {
      const prod = param.find((item) => {
        return item?.name === product?.name;
      });
      localStorage.qty = prod?.product_qty;
      setProductDetails(prod);
    };
    if (!!data?.data?.products.length && product) {
      setProductDetail(data?.data?.products);
    }
  }, [data, id]);

  const handleCancel = async () => {
    if (data?.data?.products.length > 1) {
      return;
    }

    const payload = {
      id,
      prod: data?.data?.products[0]?.product_id,
      amount: productDetails?.price - productDetails?.refund_amount,
    };
    try {
      const response = await OrderService.refundOrder(payload);
      if (response.status) {
        handleNewOrder();
        return;
      }
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const handleNewOrder = async () => {
    const selectedProduct = similarPrd.find((item) => item.product_id === prd);

  
    const payload = {
      previousOrderId: id,
      shippingId: selectedProduct.shipping_id,
      ipAddress: ip,
      campaignId: selectedProduct.campaign_id,
      offers: [
        {
          offer_id: selectedProduct.offer_id,
          product_id: selectedProduct.crm_product_id,
          billing_model_id: selectedProduct.billing_model_id,
          quantity: 1,
        },
      ],
    };



    try {
      const response = await OrderService.newOrder(payload);
      if (response.status) {
        setLoading(false);
        return navigate(`/orders/revised/${id}/complete`);
      }
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    if (data?.data?.products.length > 1) {
      // No API call as it's a multi-product order
      navigate(`/orders/revised/${id}/complete`);
    }

    handleCancel();
  };


  // console.log("product details: ", productDetails);

  return (
    <>
      <ToastContainer />
      <div className="container pagePadding">
      <h1>Purchased the wrong package?</h1>
      <h1>Let's fix this right now!</h1>
      <h4 className="iv-sub-title">Select the correct package below</h4>
      <div className="blue-line"></div>

      <div className="container">
        <DeleteSingleOrder
          name={product?.name}
          //img={product?.mainImage}
          img={imageData?.[product?.image]?.image}
          price={productDetails?.price - productDetails?.refund_amount}
        />

        {data?.data.products.length == 1 && !!similarPrd.length && (
          <div className="pkg-opt mt-4 mb-4 ">
            <h3>Other package options</h3>
            <h4>*Replace current order with another package below</h4>
          </div>
        )}

        {smProdLoading
          ? // <h1>Loading...</h1>
            new Array(3)
              .fill("")
              .map((_item, key) => (
                <Skeleton
                  key={key}
                  height={"200px"}
                  count={1}
                  style={{ margin: "10px 0px" }}
                />
              ))
          : data?.data.products.length == 1 && (
              <>
                {!!similarPrd.length &&
                  similarPrd?.map((item, idx) => (
                    <DeleteSingleOrder
                      key={idx}
                      name={item?.product_name}
                      id={item?.product_id}
                      img={imageData?.[item?.product_id]?.image}
                      //similarPrdImg = {item.image}
                      similarPrdImg = {item.product_image}
                      price={item?.price}
                      // discount={}
                      similar={true}
                      prd={prd}
                      setPrd={setPrd}
                    />
                  ))}
              </>
            )}

        {(data?.data.products.length == 1 && !!similarPrd.length) ? (
          <div className="bk-btn text-center">
            <div className="container">
              <button disabled={!prd} className="btn" onClick={handleSubmit}>
                {loading && (
                  <img
                    src={Loading}
                    alt=""
                    style={{ width: "30px", paddingRight: "5px" }}
                  />
                )}
                Continue
              </button>
            </div>

            <div className="container">
              <button className="btn" onClick={() => navigate(`/orders`)}>
                Back
              </button>
            </div>
          </div>
        ): <h1 style={{textAlign:'center'}}>Similar products are not available for this product</h1>}
      </div>
      </div>
    </>
  );
};

export default ReviseConfirmd;
