import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import useImageFetch from "../../../../hooks/useImageFetch";
import OrderService from "../../../../services/OrderService";
import Benefits from "../../../../components/Benefit";
import { isTokenValid } from "../../../../utils/isTokenValid";
import { useAuth } from "../../../../context";
import { ToastContainer, toast } from "react-toastify";

// import Skeleton from "react-loading-skeleton";

const MemberShipCancel = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatch } = useAuth();
  const [product, setProduct] = useState();

  useEffect(() => {
    try {
      const token = JSON.stringify(localStorage.getItem("token"));
      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  useEffect(() => {
    const image = JSON.stringify(JSON.parse(localStorage.getItem("image")));
    const name = JSON.parse(JSON.stringify(localStorage.getItem("name")));
    const quantity = JSON.parse(JSON.stringify(localStorage.getItem("qty")));
    const mainImage = `${localStorage.getItem("mainImage")}`;

    setProduct({ name, image, quantity, mainImage });
  }, []);

  // console.log("prodduct details: ", productDetails);

  const getSingleOrder = useCallback(() => {
    return OrderService.getSingleItem(id);
  }, [id]);

  const { data, isError, isLoading } = useFetch(getSingleOrder);
  const { imageData } = useImageFetch();

  // TODO: Need to move this function
  const handleCancel = async () => {
    if (product?.name === "Sapphire Membership") {
      return navigate(`/membership/silver-offer/${id}`);
    }

    const payload = { id, prod: data?.data?.products[0]?.product_id };
    try {
      const response = await OrderService.stopSubscription(payload);
      if (response.status) {
        navigate("/orders");
        return navigate(`/orders/cancelled/${id}`);
      }
      /*toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });*/
    } catch (error) {
      console.log("error: ", error);
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <ToastContainer />

      <Benefits
        name={product?.name}
        //img={product?.mainImage}
        img={imageData?.[product?.image]?.image}
        id={id}
        cancel={true}
      />

      <h4 className="mb-5 cp" onClick={handleCancel}>
        No, I want to cancel my membership
      </h4>
    </>
  );
};

export default MemberShipCancel;
