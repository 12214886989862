import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import menus from "../../defaultData/dashboard";
// import "./index.css";
import "../../styles/index.css";
import { useAuth } from "../../context";
import { isTokenValid } from "../../utils/isTokenValid";

function Dashboard() {
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();

  useEffect(() => {
    const token = JSON.stringify(localStorage.getItem("token"));
    try {
      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }

    localStorage.removeItem('product_image');
  }, []);

  return (
    <main>
      <div className="container pagePadding">
      <div className="iv-top text-center">
        <h2 className="iv-title">Verify your information</h2>
        <h4 className="iv-sub-title">Select an option to proceed</h4>
        <div className="blue-line"></div>
      </div>
      <div className="opt-box">
        <div className="container">
          <div className="wrapper">
            {menus.map((item, idx) =>
              idx == 0 || idx == 3 ? (
                <div
                  key={item.title}
                  className="cmn-box"
                  onClick={() => navigate(item.path)}
                >
                  <div className="box-con">
                    {<img src={item.img} className="img-fluid" alt="" />}
                    <div className="box-title">{item.title}</div>
                  </div>
                </div>
              ) : (
                
                <a
                  key={idx}
                  href={item.path}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  className="cmn-box"
                >
                  <div key={item.title} className="box-con">
                    {<img src={item.img} className="img-fluid" alt="" />}
                    <div className="box-title">{item.title}</div>
                  </div>
                </a>
              )
            )}
          </div>
        </div>
      </div>
      </div>
    </main>
  );
}

export default Dashboard;
