import { useEffect, useRef, useState } from "react";
import "./index.css";
import DefaultImage from "../../assets/images/No-image.png";
import Skeleton from "react-loading-skeleton";

const DeleteOrderItem = ({
  name,
  img,
  price,
  discount,
  prd,
  setPrd = () => {},
  about,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleBorder = (dis) => {
    return dis === discount ? "2px solid #28A745" : "";
  };

  const handleSetProd = (param) => {
    if (param) {
      setPrd(param);
      return;
    }
  };

  useEffect(() => {
    const delay = 1500;

    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <div className="container">
        <div
          className={`order-div ${!discount && "rev-order-div"}`}
          onClick={() => handleSetProd(discount)}
        >
          <div style={{ border: `${discount && handleBorder(prd)}` }}>
            <div className={`cmn-box`}>
              <div className="order-image">
                <img
                  src={img}
                  className="img-fluid"
                  alt={"Product"}
                  onError={(e) => {
                    e.target.src = DefaultImage;
                  }}
                />
              </div>
              <div className="order-content">
                <div className="oc-div oc-div-01">
                  <div className="oc-div-left"></div>
                  <div className="oc-div-right"></div>
                </div>

                <div className="oc-div oc-div-02">
                  <div className="oc-div-left pb-5">
                    <h3 className={`${!discount && "Order-product-name"}`}>
                      {isLoading ? (
                        <Skeleton
                          height={"60px"}
                          count={1}
                          style={{ margin: "10px 10px" }}
                          width={"800px"}
                        />
                      ) : (
                        about
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeleteOrderItem;
