import { useEffect, useState } from "react";
import axios from "axios";
import ImageService from "../services/ImageService";

export default function useImageFetch() {
  const [data, setData] = useState([1]);
  const [formattedImageData, setFormattedImageData] = useState(null);
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState(null);
  // const [isReady, setIsReady] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    setStatus("pending");
    setData(null);
    setError(null);

    const fetchAllImages = async () => {
      try {
        const data = await ImageService.getItems();
        setData(data);
        // setIsReady(true);
      } catch (error) {
        setError(error);
        setStatus("error");
      }
    };

    fetchAllImages();
    return () => controller.abort();
  }, []);

  // console.log("raw images: ",data);

  useEffect(() => {
    // if (isReady) {
    const newImages =
      data?.data?.length &&
      data?.data?.reduce((state, cv) => {
        state[cv.crm_product_id] = cv;
        return state;
      }, {});
    setFormattedImageData(newImages);
    // }
  }, [data]);

  // console.log("formated images: ",formattedImageData);

  return {
    imageData: formattedImageData,
    imageError: error,
    isImageLoading: status === "pending",
    isFetchSuccess: status === "success",
    isImageError: status === "error",
  };
}
