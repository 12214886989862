import React from "react";
import { renderTextWithLinks } from "../../utils/textRenderWithLink";

export default function Card({ sub, des }) {
  return (
    <div className="faq-card card text-left card">
      <div className="card-body">
        <h3 className="card-title">{sub}</h3>
        <p
          onClick={(e) => e.stopPropagation()}
          dangerouslySetInnerHTML={{ __html: renderTextWithLinks(des) }}
        />
        <a href="#" className="card-link"></a>
      </div>
    </div>
  );
}
