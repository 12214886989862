import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import OrderService from "../../../services/OrderService";
import { useAuth } from "../../../context";
import "../../../styles/index.css";
import Skeleton from "react-loading-skeleton";
import { isTokenValid } from "../../../utils/isTokenValid";
import options from "../../../defaultData/help.js";
import { ToastContainer, toast } from "react-toastify";

export default function SingleOrder() {
  const { id } = useParams();
  const { dispatch } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    (() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    })();
    try {
      const token = JSON.stringify(localStorage.getItem("token"));
      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  const getSingleProduct = useCallback(() => {
    return OrderService.getSingleItem(id);
  }, [id]);

  const { data, isSuccess, error, isError, isLoading } =
    useFetch(getSingleProduct);

  const handleProductType = useCallback(
    (arg) => {
      if (arg?.length > 1) {
        const product = arg?.find((prd) => {
          return prd.name === localStorage.getItem("name");
        });
        return product?.billing_model.id === "3" ||
          product?.name.includes("Membership")
          ? ["Membership Options", 3]
          : ["Order Options", 2];
      }
      return data?.data?.products?.[0]?.billing_model?.id === "3" ||
        data?.data?.products?.[0]?.name.includes("Membership")
        ? ["Membership Options", 3]
        : ["Order Options", 2];
    },
    [data]
  );

  const handleOnclick = (item, prdLength) => {
    if (prdLength > 1 && item.name === 'Revise Order') {
        return toast.error("Revise order is unavailable for order contains multiple product", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
      navigate(`${item.path}/${id}`, {
        state: {
          type: handleProductType(data?.data?.products)[0],
          data,
        },
      });
  };

  return (
    <div className="container pagePadding orderHelpPage">
      <ToastContainer />

      {!isLoading ? (
        <h1 className="iv-title">
          {handleProductType(data?.data?.products)[0]}
        </h1>
      ) : (
        <Skeleton
          height={"50px"}
          count={1}
          style={{ margin: "10px 0px", width: "700px" }}
        />
      )}
      {isLoading ? (
        <Skeleton
          height={"30px"}
          count={1}
          style={{ margin: "10px 0px", width: "500px" }}
        />
      ) : (
        <>
          <h4 className="iv-sub-title">Select an item below to proceed</h4>
          <div className="blue-line"></div>
        </>
      )}
      <div className="container">
        {isLoading && (
          <Skeleton
            height={"150px"}
            count={3}
            style={{ margin: "10px 0px", width: "700px" }}
          />
        )}
        {isSuccess && (
          <div className="custom-btn d-flex flex-wrap flex-md-nowrap justify-content-center">
            {options[handleProductType(data?.data?.products)[1]]?.map(
              (item, key) => (
                <button
                  key={key}
                  className="btn co-btn my-3"
                  onClick={() => handleOnclick(item, data?.data?.products.length)}
                >
                  {item.name}
                </button>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}
