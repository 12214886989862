/**
 * Takeng Status value and returning array of strings(className)
 * @param {string} arg - parameter
 * @return {string[]} array of strings
 */
export const statusLogic = (arg) => {
  return (
    (arg == "2" && ["orange-dot", "Shipment Pending"]) ||
    (arg == "6" && ["red-dot", "Cancelled"]) ||
    (arg == "7" && ["red-dot", "Declined"]) ||
    (arg == "8" && ["green-dot", "Shipped"]) ||
    (arg == "11" && ["orange-dot", "Shipment Pending"])
  );
};
