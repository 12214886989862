import { useEffect, useState } from "react";
import { isTokenValid } from "../../../utils/isTokenValid";
import { useAuth } from "../../../context";
import useImageFetch from "../../../hooks/useImageFetch";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import DefaultImage from '../../../assets/images/No-image.png';

const buttons = [
  { name: "Yes", path: "/" },
  { name: "No, Thank You", path: "/" },
];

const Complete = () => {
  const { dispatch } = useAuth();
  const navigate = useNavigate();
  const [product, setProduct] = useState();

  useEffect(() => {
    (() =>{   window.scrollTo({top: 0, behavior: 'smooth'});})();
    try {
      const token = JSON.stringify(localStorage.getItem("token"));
      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  useEffect(() => {
    const image = JSON.stringify(JSON.parse(localStorage.getItem("image")));
    const name = JSON.parse(JSON.stringify(localStorage.getItem("name")));
    const quantity = JSON.parse(JSON.stringify(localStorage.getItem("qty")));
    const mainImage = `${localStorage.getItem("mainImage")}`;
    const product_image = localStorage.getItem("product_image");
    setProduct({ name, image, quantity, mainImage, product_image });
  }, []);

  const { imageData } = useImageFetch();

  return (
    <>
      <div className="container pagePadding">
        {" "}
        <h1>Is there anything further we can assist you with?</h1>
        <h4 className="iv-sub-title">Select one option to continue</h4>
        <div className="blue-line"></div>
        <div>
          {!imageData ? (
            <Skeleton
              height={"150px"}
              width={"150px"}
              count={1}
              style={{ margin: "10px 0px" }}
            />
          ) : (
            <div
              className="membershipDisplay"
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0px auto",
              }}
            >
              <div
                style={{ height: "150px", width: "150px", margin: "0px auto" }}
              >
                {product && imageData && (
                  <img
                    src={product?.mainImage || product?.product_image}
                    //src={product?.product_image}
                    className="img-fluid"
                    alt="Product"
                    onError={(e) => {
                      e.target.src = DefaultImage;
                    }}
                  />
                )}
              </div>
              <div style={{ margin: "auto" }}>
                {" "}
                <h5>
                  {product?.quantity} x {product?.name}
                </h5>
              </div>
            </div>
          )}

          <div className="custom-btn mt-5 d-flex justify-content-center">
            {buttons?.map(function (item, idx) {
              return (
                <button
                  key={idx}
                  className="btn my-3 btn-complete"
                  onClick={() => navigate(item?.path)}
                >
                  {item?.name}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Complete;