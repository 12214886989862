import "./index.css";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Search from "../../components/Search";
import { renderTextWithLinks } from "../../utils/textRenderWithLink";

function FaqDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [search, setSearchText] = useState("");

  const handleDisplay = (arg) => (arg === state?.[0] ? true : false);
  // const handleClick = () => {
  //   // const token = JSON.stringify(localStorage.getItem("token"));
  //   // if (token) {
  //   //   return navigate(`/dashboard`);
  //   // }
  //   // return navigate("/login");
  // };

  // Process the API response text with hyperlinks
  // const processedText = renderTextWithLinks(apiResponse);

  return (
    <>
      {/* <Search state={search} setState={setSearchText} /> */}

      <div className="answer-faqs">
        <div className="container">
          <div className="af-box">
            <div className="af-title">
              <h2>{state?.[0]}</h2>
            </div>
            <div className="af-body">
              {/* <p>{state?.[1]}</p> */}
              <p dangerouslySetInnerHTML={{ __html: renderTextWithLinks(state?.[1]) }} />
            </div>
          </div>
          {/* <div className="thumb">
            <span>
              <i
                className="fa-solid fa-thumbs-up"
                style={{ color: "rgb(17 99 139)", fontSize: "20px" }}
              />
            </span>
          </div> */}
        </div>
      </div>

      {/* <div className="des-yes-no">
        <div className="container">
          <p>Did this help?</p>
          <div className="ld-btn">
            <button className="dis-like-btn cmn-btn">
              <span className="no-svg">
                <i
                  className="fa-solid fa-thumbs-up like-btn-icon"
                  // style={{ color: "rgb(17 99 139)", fontSize: "20px" }}
                />
              </span>
              No
            </button>
            <button className="like-btn cmn-btn">
              <span className="yes-svg">
                <i
                  className="fa-solid fa-thumbs-up like-btn-icon"
                  // style={{ color: "rgb(17 99 139)", fontSize: "20px" }}
                />
              </span>
              Yes
            </button>
          </div>
        </div>
      </div> */}

      

      <div className="home-icons">
        <div className="container">
          <h3 className="rq-heading">Related questions</h3>

          <div className="row justify-content-center">
            {state[2]?.map((item, idx) => (
              <div
                key={idx}
                className={`faq-choice col-md-4 col-12 ${
                  handleDisplay(item?.subject) && "d-none"
                }`}
              >
                <div className="faq-card card text-left"  onClick={() =>
                    navigate(`/faq/details`, {
                      state: [item?.subject, item?.description, state[2]],
                    })
                  }>
                  <div className="card-body">
                    <h3 className="card-title">{item?.subject}</h3>
                    {/* <p className="card-text">
                      {item?.description} */}
                      <p dangerouslySetInnerHTML={{ __html: renderTextWithLinks(item?.description) }} />
                      {/* To return your product(s)&nbsp;
                      <a onClick={(e) => e.preventDefault()} href="">
                        Click Here&gt;&gt;
                      </a>
                      &nbsp;and select the product(s) you want to return. */}
                    {/* </p> */}
                    {/* <i
                      className="fa-solid fa-thumbs-up like-icon"
                    /> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqDetails;
