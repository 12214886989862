const Search = ({ state, setState, searchTerm }) => {
  return (
    <div className="main">
      <div className="container">
        <div className="input-group has-search">
          <span className="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            className="form-control"
            placeholder="Search by topics, keywords or phrases"
            onChange={({ target }) => setState(target.value)}
          ></input>
          <div className="input-group-append">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => searchTerm(state)}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
