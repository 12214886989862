import { useLocation, useNavigate } from "react-router-dom";
import useFaqFetch from "../../../hooks/faqFetch";
import { ToastContainer, toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { useCallback } from "react";
import FAQService from "../../../services/FAQService";

const Product = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  // const endPoint = `get-sub-category-by-categoryId/${state}`;

  const getSingleFAQ = useCallback(() => {
    return FAQService.getProdItem(state);
  }, []);

  const { data, isError, isLoading, isSuccess } = useFaqFetch(getSingleFAQ);

  if (isLoading) {
    return (
      <>

      <div className="pagePadding faq-product">
        <div className="iv-top text-center">
          <h2 className="iv-title">FAQs</h2>
          <h4 className="iv-sub-title">
            <Skeleton
              height={"40"}
              count={1}
              style={{ margin: "10px 10px" }}
              width={"300px"}
            />
          </h4>

          <Skeleton
            height={"20"}
            count={1}
            style={{ margin: "10px 10px" }}
            width={"60px"}
          />

          <Skeleton
            height={"130"}
            count={1}
            style={{ margin: "10px 10px" }}
            width={"500px"}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {new Array(4).fill("").map((_item, idx) => (
            <Skeleton
              key={idx}
              height={"200px"}
              count={1}
              style={{ margin: "50px 10px" }}
              width={"250px"}
            />
          ))}
        </div>
        </div>
      </>
    );
  }

  if (isSuccess && !data?.length) {
    return <div className="pagePadding msgDisplaySec"><h1>Sorry no data found!</h1></div>;
  }

  if (isError) {
    toast.error("Invalid OTP", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return <div className="pagePadding msgDisplaySec"><h1>Something went wrong!</h1></div>;
  }

  return (
    <>
      <ToastContainer />
      <div className="pagePadding">
      <div className="home-icons">
        <div className="container">
          <div className="iv-top text-center">
            <h2 className="iv-title">FAQs</h2>
            <h4 className="iv-sub-title">
              Select a category below related to your question
            </h4>
            <div className="blue-line"></div>
          </div>
          <div className="row justify-content-center">
            {data?.map((item, idx) => (
              <div
                key={idx}
                className="choice col-sm-auto col-12"
                onClick={() => navigate(`/faq/product/${item?.id}`)}
              >
                <div className="choice-box hide-on-mob">
                  <img src={item?.sub_category_image} className="img-fluid" />
                  <h2 className="choice-name">{item?.sub_category_name}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Product;
