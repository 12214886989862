import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useFaqFetch from "../../../hooks/faqFetch";
import { ToastContainer, toast } from "react-toastify";
import Card from "../../../components/FAQCard";
import FAQService from "../../../services/FAQService";
import Skeleton from "react-loading-skeleton";
import Search from "../../../components/Search";

function FAQList() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [search, setSearchText] = useState(null);
  const [serchResult, setSearchResult] = useState([]);
  const [searched, setSearched] = useState(false);

  // const url = `${id}?base_url=${window.location.href}`;

  const url = window.location.href;

  // Split the URL by '/'
  const parts = url.split('/');

  // Extract the protocol and domain part
  const domain = parts[0] + '//' + parts[2] + '/';
  const mainUrl = `${id}?base_url=${domain}`;


  const getSingleFAQ = useCallback(() => {
    return FAQService.getSubProdItem(mainUrl);
  }, []);

  const searchTerm = (arg) => {
    const result = data?.filter((item) =>
      item.subject.toLowerCase().includes(arg.toLowerCase())
    );
    setSearched(true);
    setSearchResult(result);
  };

  useEffect(() => {
    if (!search) {
      setSearchResult([]);
      setSearched(false);
    }
  }, [search]);

  const { data, isError, isLoading, isSuccess } = useFaqFetch(getSingleFAQ);

  if (isLoading) {
    return (
      <>
        <div className="pagePadding faq-product">
          <div className="iv-top text-center">
            <h2 className="iv-title">FAQs</h2>
            <h4 className="iv-sub-title">
              <Skeleton
                height={"40"}
                count={1}
                style={{ margin: "5px 10px" }}
                width={"300px"}
              />
            </h4>

            <Skeleton
              height={"20px"}
              count={1}
              style={{ margin: "2px 10px" }}
              width={"60px"}
            />

            <Skeleton
              height={"40px"}
              count={1}
              style={{ margin: "10px 10px" }}
              width={"550px"}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {new Array(3).fill("").map((_item, idx) => (
              <Skeleton
                key={idx}
                height={"200px"}
                count={1}
                style={{ margin: "50px 10px" }}
                width={"250px"}
              />
            ))}
          </div>
        </div>
      </>
    );
  }

  if (isSuccess && !data?.length) {
    return (
      <div className="pagePadding msgDisplaySec">
        <h1>Sorry No data found!</h1>
      </div>
    );
  }

  if (isError) {
    toast.error("Invalid OTP", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return (
      <div className="pagePadding msgDisplaySec">
        <h1>Something went wrong!</h1>
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <div className="bread-nav"></div>

      <div className="iv-top text-center">
        <h2 className="iv-title">FAQs</h2>
        <h4 className="iv-sub-title">Look for your question below</h4>
        <div className="blue-line"></div>
        <Search
          state={search}
          setState={setSearchText}
          searchTerm={searchTerm}
        />
      </div>

      <div className="main">
        <div className="container">
          <div className="container">
            <div className="row justify-content-center">
              {!searched ? (
                data?.map((item, idx) => (
                  <div
                    key={idx}
                    className="faq-choice col-md-4 col-12"
                    onClick={() =>
                      navigate(`/faq/details`, {
                        state: [item?.subject, item?.description, data],
                      })
                    }
                  >
                    <Card des={item?.description} sub={item?.subject} />
                  </div>
                ))
              ) : serchResult?.length > 0 ? (
                serchResult?.map((item, idx) => (
                  <div
                    key={idx}
                    className="faq-choice col-md-4 col-12"
                    onClick={() =>
                      navigate(`/faq/details`, {
                        state: [item?.subject, item?.description, data],
                      })
                    }
                  >
                    <Card des={item?.description} sub={item?.subject} />
                  </div>
                ))
              ) : (
                <h1>No data found!</h1>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="home-icons">
        <div className="container">
          <div className="row justify-content-center"></div>
        </div>
      </div>
    </>
  );
}

export default FAQList;
