import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import menus from "../../defaultData/home";
import "./index.css";
import { statusChecker } from "../../utils/statusChecker";

function Home() {
  const navigate = useNavigate();
  // const router = useRoute();

  const state = JSON.parse(
    localStorage.getItem("status", JSON.stringify("status"))
  );

  const navigateState = statusChecker(state) || "/login";

  const handleClick = async (value) => await navigate(value);

  return (
    <main>
      <div className="pagePadding">
      <div className="iv-top text-center">
        <h2 className="iv-title">Verify Your Information</h2>
        <h4 className="iv-sub-title">Select An Option To Proceed</h4>
        <div className="blue-line"></div>
      </div>
      <div className="opt-box">
        <div className="container">
          <div className="wrapper">
            {menus?.map((item, idx) => (
              <div
                key={item.title}
                className="cmn-box"
                onClick={() =>
                  idx === 1 ? navigate(item.path) : handleClick(navigateState)
                }
              >
                <div className="box-con">
                  {<img src={item?.img} className="img-fluid" alt="" />}
                  <div className="box-title">{item.title}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      </div>
    </main>
  );
}

export default Home;
