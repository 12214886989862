import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import image from "../../../assets/images/check-img.png";

const CompleteRevision = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <div className="container pagePadding">
      <div className="mt-3 mb-3">
        <img src={image} className="img-fluid" alt="product"  />
      </div>
      <div className="comp-btn">
        <div className="container d-flex">
          <button
            className="btn my-3 btn-complete"
            onClick={() => navigate(`/orders/status/${id}`)}
          >
            View details
          </button>
          <button
            className="btn my-3 btn-complete"
            onClick={() => {
              navigate(`/orders/status/${id}/complete`);
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompleteRevision;
