import { useEffect, useState } from "react";
import axios from "axios";
import FAQService from "../services/FAQService";

export default function useFaqFetch(getFunction) {
  const [data, setData] = useState([1]);
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    setStatus("pending");
    setData(null);
    setError(null);

    const fetchFunction = async () => {
      try {
        const res = await getFunction();
        setData(res);
        setStatus("success");
      } catch (error) {
        setError(error);
        setStatus("error");
      }
    };

    

    fetchFunction();
    return () => controller.abort();
  }, []);

  return {
    data: data?.data,
    faqError: error,
    isLoading: status === "pending",
    isSuccess: status === "success",
    isError: status === "error",
  };
}
