import { BrowserRouter, Routes, Route, useRoutes } from "react-router-dom";
import Home from "../views/Home";
import Login from "../views/Auth/Login";
import Orders from "../views/Orders";
import ProtectedRoute from "./protectedRoute";
import { useAuth } from "../context";
import { statusChecker } from "../utils/statusChecker";
import { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import OTPVerify from "../views/Auth/OTPverify";
import Dashboard from "../views/Dashboard";
import OrderHelp from "../views/Orders/OrderHelp";
import { setData } from "../utils/localStorageHelper";
import ItemStatus from "../views/Orders/ItemStatus/index";
import Complete from "../views/Orders/ItemStatus/complete";
import ReviseConfirmd from "../views/Orders/OrderRevise";
import AdjustShipping from "../views/Orders/MemberShipBenifit";
import CompleteMemberShip from "../views/Orders/MemberShipBenifit/complete";
import Cancel from "../views/Orders/Cancel";
import OrderCancel from "../views/Orders/Cancel/Order";
import MemberShipCancel from "../views/Orders/Cancel/MemberShip";
import CompleteRevision from "../views/Orders/OrderRevise/complete";
import FinalOffer from "../views/Orders/Cancel/Order/finalOffer";
import FaqHome from "../views/FAQ";
import Shipping from "../views/FAQ/shipping";
import Billing from "../views/FAQ/billing";
import Product from "../views/FAQ/product";
import FAQList from "../views/FAQ/product/faq";
import FaqDetails from "../views/FAQ/detail";
import SilverOffer from "../views/Orders/Cancel/MemberShip/silverOffer";
import BasicOffer from "../views/Orders/Cancel/MemberShip/basicOffer";

const Router = () => {
  const { state, dispatch } = useAuth();

  let token =
    state?.token || localStorage.getItem("token", JSON.stringify("token"));
  const redirectPath = statusChecker(state["status"]);

  let appState = JSON.parse(
    localStorage.getItem("status", JSON.stringify("status"))
  );


  // // If app reload, it's lost context shoud sync from here
  useEffect(() => {
    const payload = setData();


    switch (
      JSON.parse(localStorage.getItem("status", JSON.stringify("status")))
    ) {
      case 0:
        dispatch("LOGIN", payload);
        break;
      case 1:
        dispatch("OTP_VERIFY", payload);
        break;
      default:
        dispatch("LOGOUT", {});
    }
  }, [state]);

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path="/" element={token ? <Dashboard /> : <Home />} />

        {/* user should not go to login page if he is logged in */}
        <Route
          element={
            <ProtectedRoute
              isAllowed={appState == null}
              redirectPath={redirectPath == "/login" ? "/dashboard" : "/verify-otp"}
            />
          }
        >
          <Route exact path="/login" element={<Login />} />
        </Route>

        {/* user should not go to verify page if he is in */}
        <Route
          element={
            <ProtectedRoute
              isAllowed={appState == 1}
              redirectPath={redirectPath}
            />
          }
        >
          <Route exact path="/verify-otp" element={<OTPVerify />} />
        </Route>

        <Route
          element={
            <ProtectedRoute isAllowed={token} redirectPath={redirectPath} />
          }
        >
          <Route exact path="/dashboard" element={<Dashboard />} />

          <Route exact path="/orders" element={<Orders />} />

          <Route exact path="/orders/help/:id" element={<OrderHelp />} />

          <Route exact path="/orders/status/:id" element={<ItemStatus />} />

          <Route
            exact
            path="/orders/status/:id/complete"
            element={<Complete />}
          />

          <Route
            exact
            path="/orders/revised/:id"
            element={<ReviseConfirmd />}
          />

          <Route
            exact
            path="/orders/revised/:id"
            element={<ReviseConfirmd />}
          />

          <Route
            exact
            path="/membership-benifit/:id"
            element={<AdjustShipping />}
          />

          <Route
            exact
            path="/membership-benifit/complete/:id"
            element={<CompleteMemberShip />}
          />

          <Route exact path="/cancel/:id" element={<Cancel />} />

          <Route exact path="/cancel/order/:id" element={<OrderCancel />} />

          <Route
            exact
            path="/orders/revised/:id/complete"
            element={<CompleteRevision />}
          />

          <Route
            exact
            path="/orders/cancel/final-offer/:id"
            element={<FinalOffer />}
          />

          <Route
            exact
            path="/orders/cancelled/:id"
            element={<CompleteRevision />}
          />

          <Route
            exact
            path="/membership/silver-offer/:id"
            element={<SilverOffer />}
          />

          <Route
            exact
            path="/membership/basic-offer/:id"
            element={<BasicOffer />}
          />

          <Route
            exact
            path="/cancel/membership/:id"
            element={<MemberShipCancel />}
          />

          <Route
            exact
            path="/membership/cancelled/:id"
            element={<CompleteRevision />}
          />
        </Route>

        <Route exact path="/faq-items" element={<FaqHome />} />

        <Route exact path="/faq/shipping" element={<Shipping />} />
        <Route exact path="/faq/billing" element={<Billing />} />
        <Route exact path="/faq/product" element={<Product />} />

        <Route exact path="/faq/product/:id" element={<FAQList />} />

        <Route exact path="/faq/details" element={<FaqDetails />} />

        <Route exact path="*" element={<h1>Page Not Found! 404</h1>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
