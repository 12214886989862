import { jwtDecode } from "jwt-decode";

export const isTokenValid = (token = false) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);
  const timeNow = Date.now();

  return new Date(timeNow).toLocaleString() >
    new Date(decoded.exp * 1000).toLocaleString()
    ? false
    : true;
};
