// import './index.css';
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../context";
import { isTokenValid } from "../../../utils/isTokenValid";
import useImageFetch from "../../../hooks/useImageFetch";
import Skeleton from "react-loading-skeleton";
import OrderService from "../../../services/OrderService";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { addDays, format, parseISO, sub } from "date-fns";
import Benefits from "../../../components/Benefit";

const billingFrequency = ["30 days", "45 days", "60 days", "90 days"];

const MemberShipBenifit = () => {
  const { id } = useParams();
  const { dispatch } = useAuth();
  const [product, setProduct] = useState();
  const [frequency, setFrequency] = useState(null);
  const [productDetails, setProductDetails] = useState();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState({
    nextDate: null,
    selectedOption: null,
  });

  useEffect(() => {
    try {
      const token = JSON.stringify(localStorage.getItem("token"));
      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  useEffect(() => {
    const image = JSON.stringify(JSON.parse(localStorage.getItem("image")));
    const name = JSON.parse(JSON.stringify(localStorage.getItem("name")));
    const quantity = JSON.parse(JSON.stringify(localStorage.getItem("qty")));
    const mainImage = `${localStorage.getItem("mainImage")}`;
    setProduct({ name, image, quantity,mainImage });
  }, []);

  // console.log("prodduct details: ", productDetails);

  const getSingleOrder = useCallback(() => {
    return OrderService.getSingleItem(id);
  }, [id]);

  const { data, isError, isLoading } = useFetch(getSingleOrder);
  const { imageData } = useImageFetch();

  const handleSelectedOption = (data) => {
    if (data?.subscription_desc === "Bills every 30 days") {
      setFrequency(billingFrequency[0]);
      setSelectedOption((prv) => ({
        ...prv,
        ["nextDate"]: data?.recurring_date,
        ["selectedOption"]: billingFrequency[0],
      }));
    }
  };

  useEffect(() => {
    const setProductDetail = (param) => {
      const prod = param.find((item) => {
        return item?.name === product?.name;
      });
      localStorage.qty = prod.product_qty;
      setProductDetails(prod);
      handleSelectedOption(prod);
    };

    if (!!data?.data?.products.length && product) {
      setProductDetail(data?.data?.products);
    }
  }, [data, id]);

  return (
    <Benefits
      name={product?.name}
      img={imageData?.[product?.image]?.image}
      //img={product?.mainImage}
      id={id}
    />
  );
};
export default MemberShipBenifit;
