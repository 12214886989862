import { useCallback, useEffect, useState } from "react";
import OrderService from "../../../../services/OrderService";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../context";
import { isTokenValid } from "../../../../utils/isTokenValid";
import useFetch from "../../../../hooks/useFetch";
import useImageFetch from "../../../../hooks/useImageFetch";
import { ToastContainer, toast } from "react-toastify";
import DeleteOrderItem from "../../../../components/DelSingleOrder/deleteItem";
import axios from "axios";
import { capitalizeFirstLetter } from "../../../../utils/capitalize";
import Loading from "../../../../assets/images/loader.gif";
import productService from "../../../../services/productService";

const BasicOffer = () => {
  const { id } = useParams();
  const { state, dispatch } = useAuth();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const user =
    state?.user_firstname || JSON.parse(localStorage.getItem("user_firstname"));
  const discount = 30;
  const [ip, setIP] = useState("");

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      setIP(res.data.IPv4);
    };
    getData();
  }, []);

  useEffect(() => {
    try {
      const token = JSON.stringify(localStorage.getItem("token"));
      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  useEffect(() => {
    const image = JSON.stringify(JSON.parse(localStorage.getItem("image")));
    const name = JSON.parse(JSON.stringify(localStorage.getItem("name")));
    const quantity = JSON.parse(JSON.stringify(localStorage.getItem("qty")));
    const mainImage = `${localStorage.getItem("mainImage")}`;

    setProduct({ name, image, quantity, mainImage });
  }, []);

  // console.log("prodduct details: ", productDetails);

  const getSingleOrder = useCallback(() => {
    return OrderService.getSingleItem(id);
  }, [id]);

  const { data, isError, isLoading } = useFetch(getSingleOrder);
  const { imageData } = useImageFetch();

  //const handleCancel = async (membership = false) => {
  const handleCancel = async () => {
    // code for combined order to cancel subscription
    let productID = "";
    let name = "";
    const prodDetails = data?.data?.products;
    prodDetails?.map((item, idx) => {
      if (item.is_recurring == 1) {
        productID = item?.product_id;
        name = item?.name;
      }
    });
    //const payload = { id, prod: data?.data?.products[0]?.product_id };
    const payload = { id, prod: productID };

    try {
      const response = await OrderService.stopSubscription(payload);
      if (response?.status) {
        /*if (membership) {
          return;
        }*/
        // return navigate("/orders");
        //return navigate(`/orders/cancelled/${id}`);
        handleNewOrder({ productID, name });
      }
      /*toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });*/
    } catch (error) {
      console.log("error: ", error);
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleContinue = () => {
    setIsButtonDisabled(true);
    //handleCancel(true);
    handleCancel();
  };

  const handleNewOrder = async ({ productID, name }) => {
    let response;
    try {
      response = await productService.getItemDetails({ id: 66, name: "Basic Membership" });
    } catch (error) {
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsButtonDisabled(false);
    }

    // fetch data from orderMappings
    const payload = {
      previousOrderId: id,
      //shippingId: data.data.shipping_id,
      shippingId: response?.data?.shipping_id,
      ipAddress: ip,
      //campaignId: data.data.campaign_id,
      campaignId: response?.data?.campaign_id,
      offers: [
        {
          offer_id: response?.data?.offer_id,
          product_id: 66,
          billing_model_id: response?.data?.billing_model_id,
          quantity: 1,
        },
      ],
    };

    try {
      const response = await OrderService.newOrder(payload);
      if (response.status) {
        return navigate(`/orders/revised/${id}/complete`);
      }
      /*toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });*/
      setIsButtonDisabled(false);
    } catch (error) {
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => {
    const setProductDetail = (param) => {
      const prod = param.find((item) => {
        return item?.name === product?.name;
      });
      localStorage.qty = prod.product_qty;
      setProductDetails(prod);
    };
    if (!!data?.data?.products.length && product) {
      setProductDetail(data?.data?.products);
    }
  }, [data, id]);

  return (
    <>
      <ToastContainer />
      <div className="pagePadding">
        <h1>Wait consider before you go!</h1>
        <h4 className="iv-sub-title">
          {user &&
            `${capitalizeFirstLetter(
              user
            )},  Keep basic membership with $5.89 `}
        </h4>
        <div className="blue-line"></div>

        <div className="container">
          <DeleteOrderItem
            name={product?.name}
            img={imageData?.[product?.image]?.image}
            //img={product?.mainImage}
            price={productDetails?.price}
            about="Yes keep basic membership"
            discount={discount}
          />

          <div className="bk-btn text-center">
            <div className="container">
              <div className="container">
                <span
                  onClick={handleContinue}
                  disabled={isButtonDisabled}
                  // disabled={true}
                  type="submit"
                  className={`btn ${isButtonDisabled && "disabled"}`}
                >
                  {isButtonDisabled && (
                    <img
                      src={Loading}
                      alt=""
                      style={{ width: "30px", paddingRight: "5px" }}
                    />
                  )}
                  Continue with this deal
                </span>
              </div>
              <span onClick={handleCancel}>
                <h4
                  className="mb-5 cp"
                  // onClick={handleCancel}
                  // onClick={() => navigate(`/orders/cancelled/${id}`)}
                >
                  No thanks, I’m declining this offer. I understand that this is
                  a one-time exclusive offer and is not available anywhere
                  outside of this page. By Clicking this link, I’m refusing to
                  take this offer.
                </h4>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicOffer;
