export const setData = () => {
   const appState = JSON.parse(
      localStorage.getItem("status", JSON.stringify("status"))
    );
    const fname = JSON.parse(
      localStorage.getItem("user_firstname", JSON.stringify("user_firstname"))
    );
    const email = JSON.parse(
      localStorage.getItem("user_email", JSON.stringify("user_email"))
    );
    const lastname = JSON.parse(
      localStorage.getItem("last_name", JSON.stringify("last_name"))
    );
    const cid = JSON.parse(
      localStorage.getItem("customer_id", JSON.stringify("customer_id"))
    );
   const token = localStorage.getItem("status", JSON.stringify("status"));
    return {
      email: email,
      first_name: fname,
      last_name: lastname,
      customer_id: cid,
      status: appState,
      token: token,
    };
  };