import { addMinutes, isAfter, parseISO } from "date-fns";

/**
 * Checks if OTP is expired or not.
 * @param {string} otpExpiredTime
 * @returns boolean
 */

export const isOTPExpired = (otpExpireTime) => {
  const otpExpiredTime = addMinutes(
    parseISO(otpExpireTime.toLocaleString()),
    15
  );
  const timeNow = new Date();
  // console.log('time now:',timeNow );
  // console.log('time exp:',otpExpiredTime );

  if (isAfter(timeNow, otpExpiredTime)) {
    return true;
  }
  return false;
};
