import requests from "./http";

class AuthService {
  login(payload) {
    return requests.post("/login", payload);
  }
  verifyLastName(payload) {
    return requests.post("/verify/lastname", payload);
  }
  verifyOTP(payload) {
    return requests.post("/verify/otp", payload);
  }
  saveUserDetails(payload) {
    return requests.post("/saveUserData", payload);
  }
}

export default new AuthService();
