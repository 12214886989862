import { useLocation, useNavigate } from "react-router-dom";
import useFaqFetch from "../../../hooks/faqFetch";
import { ToastContainer, toast } from "react-toastify";
import Card from "../../../components/FAQCard";
import { useCallback, useEffect, useState } from "react";
import Search from "../../../components/Search";
import Skeleton from "react-loading-skeleton";
import FAQService from "../../../services/FAQService";

const Shipping = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [search, setSearchText] = useState(null);
  const [serchResult, setSearchResult] = useState([]);
  const [searched, setSearched] = useState(false);
  // const endPoint = `get-faq-by-categoryId/${state}`;
  // const { data, isError, isLoading, isSuccess } = useFaqFetch(endPoint);

  // console.log("state......: ",state);
  // console.log("location", window.location.href);

const url = window.location.href;

// Split the URL by '/'
const parts = url.split('/');

// Extract the protocol and domain part
const domain = parts[0] + '//' + parts[2] + '/';
const mainUrl = `${state}?base_url=${domain}`;


  const getSingleFAQ = useCallback(() => {
    return FAQService.getSingleItem(mainUrl);
  }, []);

  const { data, isError, isLoading, isSuccess } = useFaqFetch(getSingleFAQ);

  const searchTerm = (arg) => {
    const result = data?.data?.filter((item) =>
      item.subject.toLowerCase().includes(arg.toLowerCase())
    );
    setSearched(true);
    setSearchResult(result);
  };

  useEffect(() => {
    if (!search) {
      setSearchResult([]);
      setSearched(false);
    }
  }, [search]);

  if (isLoading) {
    return (
      <>
      <div className="pagePadding faq-shipping">
        <div className="iv-top text-center">
          <h2 className="iv-title">FAQs</h2>
          <h4 className="iv-sub-title">
            <Skeleton
              height={"40"}
              count={1}
              style={{ margin: "10px 10px" }}
              width={"300px"}
            />
          </h4>

          <Skeleton
            height={"20"}
            count={1}
            style={{ margin: "5px 10px" }}
            width={"60px"}
          />

          <Skeleton
            height={"130"}
            count={1}
            style={{ margin: "2px 10px" }}
            width={"500px"}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {new Array(3).fill("").map((_item, idx) => (
            <Skeleton
              key={idx}
              height={"200px"}
              count={1}
              style={{ margin: "50px 10px" }}
              width={"250px"}
            />
          ))}
        </div>
        </div>
      </>
    );
  }

  if (isSuccess && !data?.length) {
    return <div className="pagePadding faq-shipping"><h1>Sorry no data found!</h1></div>;
  }

  if (isError) {
    toast.error("Invalid OTP", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return <div className="pagePadding faq-shipping"><h1>Something went wrong!</h1></div>;
  }

  return (
    <>
      <ToastContainer />
      <div className="bread-nav">
        <div className="container">
          <nav aria-label="breadcrumb">
            {/* <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Main Menu</a></li>
                    <li class="breadcrumb-item"><a href="#">Topics</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Faqs</li>
                </ol> */}
          </nav>
        </div>
      </div>
      <Search state={search} setState={setSearchText} searchTerm={searchTerm} />

      <div className="iv-top text-center">
        <h2 className="iv-title">Look for your question below</h2>
        <div className="blue-line"></div>
      </div>
      <div className="home-icons">
        <div className="container">
          <div className="row justify-content-center">
            {!searched ? (
              data?.map((item, idx) => (
                <div
                  key={idx}
                  className="faq-choice col-md-4 col-12"
                  onClick={() =>
                    navigate(`/faq/details`, {
                      state: [item?.subject, item?.description,data],
                    })
                  }
                >
                  <Card des={item?.description} sub={item?.subject} />
                </div>
              ))
            ) : serchResult?.length > 0 ? (
              serchResult?.map((item, idx) => (
                <div
                  key={idx}
                  className="faq-choice col-md-4 col-12"
                  onClick={() =>
                    navigate(`/faq/details`, {
                      state: [item?.subject, item?.description, data],
                    })
                  }
                >
                  <Card des={item?.description} sub={item?.subject} />
                </div>
              ))
            ) : (
              <h1>No data found!</h1>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Shipping;
