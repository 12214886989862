import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../context";
import { isTokenValid } from "../../../utils/isTokenValid";
import useImageFetch from "../../../hooks/useImageFetch";
import Skeleton from "react-loading-skeleton";
import OrderService from "../../../services/OrderService";
import { useParams, useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import NotfoundImage from "../../../assets/images/No-image.png"

const CompleteMemberShip = () => {
  const { id } = useParams();
  const { dispatch } = useAuth();
  const [product, setProduct] = useState();
  const [frequency, setFrequency] = useState(null);
  const [productDetails, setProductDetails] = useState();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState({
    nextDate: null,
    selectedOption: null,
  });


  useEffect(() => {
    try {
      const token = JSON.stringify(localStorage.getItem("token"));
      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  useEffect(() => {
    const image = JSON.stringify(JSON.parse(localStorage.getItem("image")));
    const name = JSON.parse(JSON.stringify(localStorage.getItem("name")));
    const quantity = JSON.parse(JSON.stringify(localStorage.getItem("qty")));
    setProduct({ name, image, quantity });
  }, []);

  const getSingleOrder = useCallback(() => {
    return OrderService.getSingleItem(id);
  }, [id]);

  const { data, isError, isLoading } = useFetch(getSingleOrder);
  const { imageData } = useImageFetch();

  return (
    <section className="pagePadding">
    <div className="container">
      <h1 className="iv-title">Is there anything else we can help you with?</h1>
      <h4 className="iv-sub-title">Select one option to continue</h4>

      <div>
        {!imageData ? (
          <Skeleton
            height={"150px"}
            width={"150px"}
            count={1}
            style={{ margin: "10px 0px" }}
          />
        ) : (
          <div
            className="membershipDisplay"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0px auto",
            }}
          >
            <div
              style={{ height: "150px", width: "150px", margin: "30px auto" }}
            >
              {product && imageData && (
                <img
                  src={imageData[product?.image]?.image || NotfoundImage}
                  //src={product?.mainImage}
                  className="img-fluid"
                  alt={"Product"}
                  onError={(e) => {
                    e.target.src = NotfoundImage;
                  }}
                />
              )}
            </div>
            <div style={{ margin: "auto" }}>
              {" "}
              <h5>
                {product?.quantity} x {product?.name}
              </h5>
            </div>
          </div>
        )}
      </div>

      <div
       className="custom-btn d-flex justify-content-center mt-3"
      >
        {/* <button className="btn my-3 btn-complete" onClick={()=> navigate(`/complete/benifit/${id}`)}>Continue</button> */}
        <button  className="btn co-btn my-3" onClick={() => navigate(`/`)}>
          Yes
        </button>
        <button  className="btn co-btn my-3" onClick={() => navigate(`/`)}>
          No, thank You
        </button>
      </div>
    </div>
    </section>
  );
};

export default CompleteMemberShip;
