import "./App.css";
import Router from "./routes";
import { AuthProvider, useAuth } from "./context";
import { setData } from "./utils/localStorageHelper";
import { useEffect } from "react";
import { statusChecker } from "./utils/statusChecker";

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Router />
      </div>
    </AuthProvider>
  );
}

export default App;
