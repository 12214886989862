import React, { useEffect, useRef, useState } from "react";
import mainLogo from "../../assets/images/ghc-logo.png";
import { Link, useLocation } from "react-router-dom";
import "../../styles/index.css";
import "./index.css";
import { useAuth } from "../../context";
// import ReactDOM from 'react-dom';

function Navbar(props) {
  const { state, dispatch } = useAuth();
  const variable = useLocation();

  // useEffect(()=>{
  //   const element = document.getElementById('google_translate_element');
  //   // console.log("element: ",element);
  //   // const elements = element[1];
  //   const path = window.location.pathname;
  //   console.log("path: ",path);
  //   if(variable.pathname !== "/" || path !== "/" ){
  //     console.log("Not in home")
  //     element.style.display = element ? 'block' : 'none';
  //     // console.log("Hi");
  //     // elements.parentNode.removeChild(elements);
  //     // elements.remove();
  //   };
  // },[variable]);
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  const user =
    state.user_firstname ?? JSON.parse(localStorage.getItem("user_firstname"));

  return (
    <header className="nwHeader">
      <div className="nwHdr-top">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-5 text-left">
              <div className="col-left-inner">
                {variable.pathname !== "/" &&
                  variable.pathname !== "/dashboard" && (
                    <Link className="main-menu-btn link-btn" to="/">
                      <i className="fa-solid fa-house"></i> <span>Home</span>
                    </Link>
                  )}
                <div className="languageDiv">
                  {/* <h5
                    onClick={() => window.location.reload()}
                    style={{
                      display: "block",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    Select Language
                  </h5> */}
                  <div id="google_translate_element"></div>
                </div>
              </div>
              
            </div>
            <div className="col-md-2 text-center">
              <Link className="mwLogo" to="/">
                <img src={mainLogo} className="img-fluid" alt="" />
              </Link>
            </div>
            <div className="col-md-5 logo_col text-right">
              <Link className="user-sec-btn link-btn" to="/">
                {user && (
                  <>
                    <i className="fa-solid fa-user mx-2" />
                    {user}
                  </>
                )}
                <div className="popup">
                  <p
                    className="popup-btn"
                    onClick={() => dispatch({ type: "LOGOUT" })}
                  >
                    Log Out
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
