import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import OrderService from "../../../services/OrderService";
import { useCallback, useEffect, useState } from "react";
import { isTokenValid } from "../../../utils/isTokenValid";
import { useAuth } from "../../../context";
import Skeleton from "react-loading-skeleton";
import useImageFetch from "../../../hooks/useImageFetch";
import { statusLogic } from "../../../utils/statusLogic";
import { formattedDate } from "../../../utils/dateFormat";
import DefaultImage from "../../../assets/images/No-image.png";
import { getTotalPaid } from "../../../utils/totalPaid";

const ItemStatus = () => {
  const { id } = useParams();
  const { dispatch } = useAuth();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    (() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    })();
    try {
      const token = JSON.stringify(localStorage.getItem("token"));
      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  useEffect(() => {
    const image = JSON.stringify(JSON.parse(localStorage.getItem("image")));
    const name = JSON.parse(JSON.stringify(localStorage.getItem("name")));
    const mainImage = `${localStorage.getItem("mainImage")}`;
    setProduct({ name, image, mainImage});
  }, []);

  const getSingleOrder = useCallback(() => {
    return OrderService.getSingleItem(id);
  }, [id]);

  const { data, isSuccess, error, isError, isLoading } =
    useFetch(getSingleOrder);
  const { imageData } = useImageFetch();

  useEffect(() => {
    const setProductDetail = (param) => {
      const prod = param.find((item) => {
        return item?.name === product?.name;
      });
      localStorage.qty = prod?.product_qty;
      setProductDetails(prod);
    };
    if (!!data?.data?.products?.length && product) {
      setProductDetail(data?.data?.products);
    }
  }, [data, id]);

  // console.log(productDetails);

  return (
    <main>
      <div className="container  pagePadding">
      {!isLoading ? (
        <>
          <h1>Item details</h1>
          <div className="blue-line"></div>
        </>
      ) : (
        <Skeleton
          height={"50px"}
          count={1}
          style={{ margin: "10px 0px", width: "700px" }}
        />
      )}

      {isLoading ? (
        <Skeleton
          height={"200px"}
          width={"1200px"}
          count={1}
          style={{ margin: "10px 0px", width: "700px" }}
        />
      ) : (
        <div className="order-div">
          <div className="container">
            <div className={`cmn-box`}>
              {
                <div className="order-image">
                  {product && (
                    <img
                      /*src={
                        product?.mainImage
                      }*/
                      src={imageData &&  imageData[product?.image] ? imageData[product?.image]?.image : DefaultImage}
                      alt="product"
                      className="img-fluid"
                      onError={(e) => {
                        e.target.src = DefaultImage;
                      }}
                    />
                  )}
                </div>
              }
              <div className="order-content">
                <div className="oc-div oc-div-01">
                  <div className="oc-div-left">
                    <h3 className="product-name">{product?.name}</h3>
                    <h4 className="product-price">
                      $<span>{productDetails?.price}</span>
                    </h4>
                    <h5 className="quantity">
                      Quantity: <span>{productDetails?.product_qty}</span>
                    </h5>
                  </div>
                  <div className="oc-div-right">
                    <h5 className="ot-purchase">
                      {productDetails?.billing_model?.id == "3"
                        ? "Subscription"
                        : productDetails?.billing_model?.name}
                    </h5>
                    {/** changed*/}
                    <h5 className="ot-purchase ">
                      Total:${data?.data?.order_total}
                    </h5>
                    <h5 className="ot-purchase">
                      Total paid:${getTotalPaid({
                        price: data?.data?.order_total,
                        discount: data?.data?.refund_amount,
                      })}
                    </h5>
                  </div>
                </div>

                <div className="oc-div oc-div-03">
                  <div className="oc-div-left">
                    {productDetails?.is_shippable !== "0" && (
                      <h3 className="order-status">
                        <div
                          className={`${
                            statusLogic(data?.data?.order_status)[0]
                          }`}
                        ></div>
                        {statusLogic(data?.data?.order_status)[1]}
                      </h3>
                    )}

                    {productDetails?.is_shippable == "0" &&
                      productDetails?.is_recurring === "0" &&
                      productDetails?.billing_model?.id == "3" && (
                        <>
                          <h3 className="order-status">
                            <div className={`red-dot`}></div>
                            Cancelled
                          </h3>
                          <h5 className="os-detail">
                            #{data?.data?.order_id} |{" "}
                            {data?.data &&
                              formattedDate(data?.data?.acquisition_date)}
                            {data?.data?.is_refund !== "no" && "|  Refunded"}
                          </h5>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-btn d-flex justify-content-center">
              <button
                className="btn co-btn my-3 mx-3"
                onClick={() => navigate(`/orders`)}
              >
                Back
              </button>

              <button
                className="btn co-btn my-3"
                onClick={() => navigate(`/orders/status/${id}/complete`)}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
    </main>
  );
};
export default ItemStatus;
