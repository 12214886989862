import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import DefaultImage from "../../assets/images/No-image.png";
import useFetch from "../../hooks/useFetch";
import OrderService from "../../services/OrderService";
import { useAuth } from "../../context";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { isTokenValid } from "../../utils/isTokenValid";
import useImageFetch from "../../hooks/useImageFetch";
import { formattedDate } from "../../utils/dateFormat";
import { statusLogic } from "../../utils/statusLogic";
import { getTotalPaid } from "../../utils/totalPaid";

function Orders() {
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const ref = useRef(null);
  // const { state: stateData } = useLocation();

  const email =
    state?.user?.user_email ||
    JSON.parse(
      localStorage?.getItem("user_email", JSON.stringify("user_email"))
    );

  const scrollToCustom = () => {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    (() => {
      scrollToCustom();
      // window.scrollTo({ top: 0, behavior: "smooth" });
    })();
    try {
      const token = JSON.stringify(localStorage.getItem("token"));

      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  const getProduct = useCallback(() => {
    return OrderService.getItems({ email });
  }, [email]);

  const { data, isSuccess, error, isError, isLoading } = useFetch(getProduct);
  const { imageData } = useImageFetch();

  const handleHelp = (url, name, img, mainImage) => {
    localStorage.setItem("image", img);
    localStorage.setItem("name", name);
    localStorage.setItem("mainImage", mainImage);
    navigate(url);
  };

  const handleProductRender = (data) => {
    return data?.data.length < 1 ? (
      <h1 className="py-5">No Order Found</h1>
    ) : (
      data?.data
        ?.map((item, idx) =>
          item?.products?.length > 1 ? (
            //  code for which order having multiple products

            <div
              key={idx}
              style={{
                background: "#f6f8fc",
                margin: "15px 0px 15px auto",
                padding: "20px 20px",
                borderRadius: "10px",
                boxShadow: "0px 5px 3px -3px rgba(0, 0, 0, 0.1)",
              }}
            >
              {item?.products?.map((singleItem, id) => (
                <div key={id}>
                  <div className={`cmn-box  `}>
                    <div className={`order-image`}>
                      <img
                        className="img-fluid"
                        //src={singleItem?.["image"]}
                        src={
                          imageData?.[singleItem?.product_id]?.image||DefaultImage
                        }
                        alt="Order"
                        onError={(e) => {
                          e.target.src = DefaultImage;
                        }}
                      />
                    </div>

                    <div className="order-content">
                      <div className="oc-div oc-div-01">
                        <div className="oc-div-left">
                          <h3 className="product-name">{singleItem?.name}</h3>
                          <h4 className="product-price">
                            $<span>{singleItem?.price}</span>
                          </h4>
                          <h5 className="quantity">
                            Quantity: <span>{singleItem?.product_qty}</span>
                          </h5>
                        </div>
                        <div className="oc-div-right">
                          <h5 className="ot-purchase">
                            {singleItem?.billing_model?.id == "3"
                              ? "Subscription"
                              : item?.products?.[0]?.billing_model?.name}
                          </h5>
                          <button
                            className="gh-btn btn"
                            onClick={() =>
                              handleHelp(
                                `/orders/help/${item.order_id}`,
                                singleItem?.name,
                                singleItem?.product_id,
                                //singleItem?.["image"]
                                imageData?.[singleItem?.product_id]?.image||DefaultImage
                              )
                            }
                          >
                            Get Help
                          </button>
                        </div>
                      </div>

                      <div className="oc-div oc-div-02">
                        <div className="oc-div-left"></div>
                        <div className="oc-div-right"></div>
                      </div>

                      <div className="oc-div oc-div-03">
                        <div className="oc-div-left">
                          {singleItem?.is_recurring === "0" &&
                          singleItem?.billing_model?.id == "3" ? (
                            <h3 className="order-status">
                              <div className={`red-dot`}></div>
                              Cancelled
                            </h3>
                          ) : (
                            (singleItem?.billing_model?.id == "3" &&
                              singleItem?.is_recurring == "0") === false &&
                            singleItem?.is_shippable !== "0" && (
                              <h3 className="order-status">
                                <div
                                  className={`${
                                    statusLogic(item?.order_status)[0]
                                  }`}
                                ></div>
                                {statusLogic(item?.order_status)[1]}
                              </h3>
                            )
                          )}
                          <h5 className="os-detail">
                            #{item?.order_id} |{" "}
                            {item?.products?.[0].billing_model.id == "3"
                              ? formattedDate(item?.recurring_date)
                              : formattedDate(item?.acquisition_date)}
                            {item?.totals_breakdown?.refunded > "0" &&
                              singleItem.refund_amount != "0.00" &&
                              "|  Refunded"}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="oc-div oc-div-02 px-4">
                <div className="oc-div-left">
                  <h5 className="sub-total">
                    Subtotal:
                    <span>${item?.totals_breakdown?.subtotal}</span>
                  </h5>
                  <h5 className="shipping">
                    Shipping:
                    <span>${item?.totals_breakdown?.shipping}</span>
                  </h5>

                  <h5 className="total-amount">
                    Total:<span>${item?.totals_breakdown?.total}</span>
                  </h5>
                </div>
                <div className="oc-div-right">
                  <h5 className="paid-amount">
                    Amount Paid:
                    <span>${item?.totals_breakdown?.subtotal}</span>
                  </h5>
                  <h5 className="refund">
                    Refunded:<span>- ${item?.refund_amount}</span>
                  </h5>

                  <h5 className="total-paid">
                    Total Paid:
                    <span>
                      ${getTotalPaid({
                        price: item?.totals_breakdown?.subtotal,
                        discount: item?.refund_amount,
                      })}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          ) : (
            // code for which order having single product only

            <div key={idx} className={`cmn-box`}>
              <div className="order-image">
                <img
                  className="img-fluid"
                  //src={item?.products?.[0]?.["image"]}
                  src={
                    imageData[item?.products?.[0]?.product_id]?.image||DefaultImage
                  }
                  alt="Order"
                  onError={(e) => {
                    e.target.src = DefaultImage;
                  }}
                />
              </div>
              <div className="order-content">
                <div className="oc-div oc-div-01">
                  <div className="oc-div-left">
                    <h3 className="product-name">
                      {item?.products?.[0]?.name}
                    </h3>
                    <h4 className="product-price">
                      $<span>{item?.order_total}</span>
                    </h4>
                    <h5 className="quantity">
                      Quantity: <span>{item?.main_product_quantity}</span>
                    </h5>
                  </div>
                  <div className="oc-div-right">
                    <h5 className="ot-purchase">
                      {item?.products?.[0]?.billing_model?.id == "3"
                        ? "Subscription"
                        : item?.products?.[0]?.billing_model?.name}
                    </h5>
                    <button
                      className="gh-btn btn"
                      onClick={() =>
                        handleHelp(
                          `/orders/help/${item?.order_id}`,
                          item?.products?.[0]?.name,
                          item?.products?.[0]?.product_id,
                          //item?.products?.[0]?.["image"]
                          imageData[item?.products?.[0]?.product_id]?.image||DefaultImage
                        )
                      }
                    >
                      Get Help
                    </button>
                  </div>
                </div>

                <div className="oc-div oc-div-02">
                  <div className="oc-div-left">
                    <h5 className="sub-total">
                      Subtotal:
                      <span>${item?.totals_breakdown?.subtotal}</span>
                    </h5>
                    <h5 className="shipping">
                      Shipping:
                      <span>${item?.totals_breakdown?.shipping}</span>
                    </h5>
                  </div>
                  <div className="oc-div-right">
                    <h5 className="paid-amount">
                      Amount Paid:
                      <span>${item?.totals_breakdown?.subtotal}</span>
                    </h5>
                    <h5 className="refund">
                      Refunded:<span>- ${item?.refund_amount}</span>
                    </h5>
                  </div>
                </div>

                <div className="oc-div oc-div-03">
                  <div className="oc-div-left">
                    {item?.shippable !== "0" && (
                      <h3 className="order-status">
                        <div
                          className={`${statusLogic(item?.order_status)?.[0]}`}
                        ></div>
                        {statusLogic(item?.order_status)?.[1]}
                      </h3>
                    )}
                    <h5 className="os-detail">
                      #{item?.order_id} |{" "}
                      {item?.products?.[0].billing_model.id == "3"
                        ? formattedDate(item?.recurring_date)
                        : formattedDate(item?.acquisition_date)}
                      {item?.totals_breakdown?.refunded > "0" && "|  Refunded"}
                    </h5>
                  </div>
                  <div className="oc-div-right">
                    <h5 className="total-amount">
                      Total:<span>${item?.totals_breakdown?.total}</span>
                    </h5>
                    <h5 className="total-paid">
                      Total Paid:
                      <span>
                        {" "}
                        ${getTotalPaid({
                          price: item?.totals_breakdown?.subtotal,
                          discount: item?.refund_amount,
                        })}
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          )
        )
        .reverse()
    );
  };

  const render = useMemo(() => {
    if (isSuccess) {
      const response = handleProductRender(data);
      return response;
    }

    if (isError) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return (
        <>
          <h1>Oops Something Went Wrong!</h1>
          <ToastContainer />
        </>
      );
    }
    if (isLoading) {
      return (
        <Skeleton height={"250px"} count={5} style={{ margin: "10px 0px" }} />
      );
    }
  }, [data, isSuccess, error, isError, isLoading]);

  return (
    <main ref={ref}>
      <div className="container pagePadding">
        <div className="iv-top text-center">
          <h2 className="iv-title">My orders</h2>
          <h4 className="iv-sub-title">Select an item below to proceed</h4>
          <div className="blue-line"></div>
        </div>

        <div className="order-div">
          <div className="container">{render}</div>
        </div>
      </div>
    </main>
  );
}

export default Orders;
