import React, { useContext, useState } from "react";
import "./index.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import AuthService from "../../../services/AuthService";
import { useAuth } from "../../../context";
import { useNavigate } from "react-router-dom";
// import "../../styles/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../assets/images/loader.gif";

function Login() {
  const navigate = useNavigate();
  const { dispatch } = useAuth();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email"),
    phone: Yup.string().matches(/^[0-9]{10}$/, "Invalid phone number"),
  });

  const handleSubmit = async ({ email, phone }) => {
    setIsButtonDisabled(true);
    if (!email && !phone) {
      toast.error("Email or Phone is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return setIsButtonDisabled(false);
    }

    if (email) {
      try {
        const response = await AuthService.login({ email });
        handleResponse(response);
        return;
      } catch ({ response }) {
        setIsButtonDisabled(false);
        const errorMsg = response?.data?.message || response?.data?.errors[0]?.message;
        toast.error(errorMsg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };

    if (phone) {
      try {
        const response = await AuthService.login({ phone });
        handleResponse(response);
        return;
      } catch ({ response }) {
        setIsButtonDisabled(false);
        const errorMsg = response?.data?.message || response?.data?.errors[0]?.message;
        toast.error(errorMsg, {
          position: toast.POSITION.TOP_RIGHT,
        });
       return setIsButtonDisabled(false);
      }
    }
  };

  const handleResponse = async (response) => {
    toast.success("Please check your email to get the OTP", {
      position: toast.POSITION.TOP_RIGHT,
    });

    if (response.status) {
      const payload = {
        email: response?.data?.email,
        first_name: response?.data?.first_name,
        customer_id: response.customer_id,
        secret: response.secret,
      };

      setTimeout(() => {
        return dispatch({ type: "LOGIN", payload });
      }, 3000);
    }
  };

  return (
    <main>
      <ToastContainer />
      <div className="pagePadding">
      <div className="iv-top text-center">
        <h2 className="iv-title">Let's locate your account!</h2>
        <h4 className="iv-sub-title">Complete a field to proceed</h4>
        <div className="blue-line"></div>
      </div>

      <div className="opt-box">
        <div className="container">
          <Formik
            initialValues={{ email: "", phone: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className="locateAccount">
                <div className="form-group">
                  <Field
                    name="email"
                    type="email"
                    placeholder="Email"
                    className="form-control"
                  />
                  {errors.email && touched.email ? (
                    <h5 style={{ color: "red" }}>{errors.email}</h5>
                  ) : null}
                </div>
                <h4>or</h4>
                <div className="form-group">
                  <Field
                    name="phone"
                    type="tel"
                    placeholder="Phone"
                    className="form-control"
                  />
                  {errors.phone && touched.phone ? (
                    <h5 style={{ color: "red" }}>{errors.phone}</h5>
                  ) : null}
                </div>
                <button
                  disabled={isButtonDisabled}
                  // disabled={true}
                  type="submit"
                  className={`btn ${isButtonDisabled && "disabled"}`}
                >
                  {isButtonDisabled && (
                    <img
                      src={Loading}
                      alt=""
                      style={{ width: "30px", paddingRight: "5px" }}
                    />
                  )}
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      </div>
    </main>
  );
}

export default Login;
