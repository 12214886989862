import requests from "./http";

class OrderService {
  getItems(payload) {
    return requests.post(`/orders`, payload);
  }
  getSingleItem(id) {
    return requests.get(`/orders/${id}`);
  }
  stopSubscription(payload) {
    return requests.post(`/orders/subscription-stop`, payload);
  }
  refundOrder(payload) {
    return requests.post("/orders/refund", payload);
  }
  // revise order 
  newOrder(payload) {
    return requests.post("/orders/new", payload);
  }
}

export default new OrderService();
