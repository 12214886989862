import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../styles/index.css";
import { useAuth } from "../../context";

function Footer() {
  const { state, dispatch } = useAuth();
  const variable = useLocation();
  return (
    <footer className="nwFootter">
      {/* Link copy rights   */}
      <div className="nwFootter_content text-center">
        <div className="container-fluid">
          <ul className="f_quick_links">
          <ul className="f_quick_links">
                            <li>
                                <Link to='#' onClick={(event) => {
                                    event.preventDefault();
                                    window.open('https://www.wholesalehealthclub.com/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Terms And Conditions | </Link>
                            </li>
                            <li>
                                <Link to="#" onClick={(event) => {
                                    event.preventDefault();
                                    window.open('https://www.wholesalehealthclub.com/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Privacy Policy | </Link>
                            </li>
                            <li>
                                <Link to="#" onClick={(event) => {
                                    event.preventDefault();
                                    window.open('https://www.wholesalehealthclub.com/citations', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Citations | </Link>
                            </li>
                            <li>
                                <Link to='https://www.wholesalehealthclub.com/contact-us'> Contact Us </Link>
                            </li>
                        </ul>
          </ul>
          <p>Copyright © {new Date().getFullYear()} | Wholesale Health Club | PO BOX 90129 Lakeland FL 33804 | Billing <br /> descriptor: <a href='https://wholesalehealthclub.com/'>wholesalehealthclub.com</a> | Customer Service&nbsp;
                        <span>Phone: <a href="tel:(480)573-0829">(480)573-0829</a></span> <span> | Customer Service Email: <br /><a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a></span></p>
          <div className="footer-leave-btn">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 text-left d-none">
                  {variable.pathname !== "/" && (
                    <Link className="main-menu-btn link-btn" to="/">
                      <i className="fa-solid fa-house px-1" ></i>
                      Main Menu
                    </Link>
                  )}
                </div>
                <div className="col-6 logo_col text-right d-none">
                  <p
                    className="user-sec-btn link-btn op"
                  >
                    {JSON.parse(localStorage.getItem("user_firstname")) && (
                      <span onClick={() => dispatch({ type: "LOGOUT" })}>
                        <i className="fa-solid fa-right-from-bracket px-2"></i>
                        Log-Out
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
