import { useLocation, useNavigate, useParams } from "react-router-dom";
import menus from "../../../defaultData/cancel";
import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { isTokenValid } from "../../../utils/isTokenValid";
import OrderService from "../../../services/OrderService";
import { useAuth } from "../../../context";
import { ToastContainer, toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

const CancelOrder = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatch } = useAuth();

  const [product, setProduct] = useState(null);
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    try {
      const token = JSON.stringify(localStorage.getItem("token"));
      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  useEffect(() => {
    const image = JSON.stringify(JSON.parse(localStorage.getItem("image")));
    const name = JSON.parse(JSON.stringify(localStorage.getItem("name")));
    setProduct({ name, image });
  }, []);

  const getSingleProduct = useCallback(() => {
    return OrderService.getSingleItem(id);
  }, [id]);

  const { data, isSuccess, error, isError, isLoading } =
    useFetch(getSingleProduct);

  useEffect(() => {
    const setProductDetail = (param) => {
      const prod = param?.find((item) => {
        return item?.name === product?.name;
      });
      localStorage.qty = prod?.product_qty;
      setProductDetails(prod);
    };
    if (!!data?.data?.products?.length && product) {
      setProductDetail(data?.data?.products);
    }
  }, [data, id]);

  const handleSubmit = async (payload) => {
    if (data?.data?.products.length > 1) {
      navigate(`/orders/cancelled/${id}`);
      return;
    }

    try {
      const response = await OrderService.refundOrder(payload);
      if (response.status) {
        navigate(`/orders/cancelled/${id}`);
        return;
      }
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleCancel = (item) => {
    if (productDetails?.refund_amount === "0.00") {
      navigate(`${item?.path}/${id}`, { state: state?.data?.data });
      return;
    }
    const payload = {
      id,
      prod: data?.data?.products[0]?.product_id,
      amount: Number(productDetails?.price) - Number(data?.data?.refund_amount),
    };
    handleSubmit(payload);
  };

  return (
    <>
      <ToastContainer />
      <div className="pagePadding cancleOrderPage">
      <h1>Why do you want to cancel</h1>
      <h4 className="iv-sub-title">Select one reason for canceling</h4>
      <div className="blue-line"></div>
      <div className="container">
        {isLoading ? (
          <Skeleton
            height={"150px"}
            count={3}
            style={{ margin: "10px 0px", width: "700px" }}
          />
        ) : (
          <div className="custom-btn d-flex justify-content-center">
            {menus[state?.type]?.map((item, key) => (
              <button
                key={key}
                className="btn co-btn my-3"
                // onClick={() => navigate(`${item.path}/${id}`,  {state: state.data.data} )}
                onClick={() => handleCancel(item)}
              >
                {item?.name}
              </button>
            ))}
          </div>
        )}
      </div>
      </div>
    </>
  );
};

export default CancelOrder;
