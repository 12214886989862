import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../context";
import { useCallback, useEffect, useState } from "react";
import { isTokenValid } from "../../../../utils/isTokenValid";
import useImageFetch from "../../../../hooks/useImageFetch";
import useFetch from "../../../../hooks/useFetch";
import OrderService from "../../../../services/OrderService";
import DeleteOrderItem from "../../../../components/DelSingleOrder/deleteItem";
import { getRefundAmount } from "../../../../utils/getRefundAmount";
import { toast } from "react-toastify";
import { isBrainFunnelCampaign } from "../../../../utils/isBrainfunnel";
import { capitalizeFirstLetter } from "../../../../utils/capitalize";
import Loading from "../../../../assets/images/loader.gif";


const OrderCancel = () => {
  const { id } = useParams();
  const { state, dispatch } = useAuth();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [referredProduct, setReferredProduct] = useState(null);
  const [revisedOrder, setRevisedOrder] = useState([]);
  const [discount, setDiscount] = useState(15);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // const [prd, setPrd] = useState();
  // const discount = 15;
  const user =
    state?.user?.user_firstname ||
    JSON.parse(localStorage.getItem("user_firstname"));

  useEffect(() => {
    try {
      const token = JSON.stringify(localStorage.getItem("token"));
      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  const getSingleProduct = useCallback(() => {
    return OrderService.getSingleItem(id);
  }, [id]);

  useEffect(() => {
    const image = JSON.stringify(JSON.parse(localStorage.getItem("image")));
    const name = JSON.parse(JSON.stringify(localStorage.getItem("name")));
    const mainImage = `${localStorage.getItem("mainImage")}`;
    setProduct({ name, image, mainImage });
  }, []);

  const { data, isSuccess, error, isError, isLoading } =
    useFetch(getSingleProduct);
  const { imageData } = useImageFetch();

  useEffect(() => {
    const setProductDetail = (param) => {
      const prod = param.find((item) => {
        return item?.name === product?.name;
      });
      localStorage.qty = prod.product_qty;
      setProductDetails(prod);
    };
    if (!!data?.data?.products.length && product) {
      setProductDetail(data?.data?.products);
    }

    if (isBrainFunnelCampaign(data?.data?.campaign_id)) {
      setDiscount(50);
    }
  }, [data, id]);

  // console.log("ProductDetails: ", data?.data.campaign_id);

  const handleSubmit = async (payload) => {
    // if (data?.data?.products.length > 1) {
    //   navigate(`/orders/cancelled/${id}`);
    //   return;
    // }

    try {
      const response = await OrderService.refundOrder(payload);
      if (response.status) {
        navigate(`/orders/cancelled/${id}`);
        return;
      }
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsButtonDisabled(false);
    } catch (error) {
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsButtonDisabled(false);
    }
  };

  const handleContinue = () => {
    setIsButtonDisabled(true);
    const payload = {
      id,
      prod: productDetails?.product_id,
      amount: getRefundAmount({
        price: productDetails?.price,
        discount,
      }),
    };
    handleSubmit(payload);
  };

  return (
    <>
      <div className="container pagePadding">
        <h1>Wait consider before you go!</h1>
        <h4 className="iv-sub-title">
          {user &&
            `${capitalizeFirstLetter(user)},  Keep your order and we'll issue you a ${discount}% `}
          Refund.
        </h4>
        <div className="blue-line"></div>

        <div className="container">
          <DeleteOrderItem
            name={product?.name}
            img={imageData?.[product?.image]?.image}
            //img={product?.mainImage}
            price={productDetails?.price}
            about={`YES! Keep my order and process my $${getRefundAmount({
              price: productDetails?.price,
              discount,
            })} refund now`}
            discount={discount}
          />

          <div className="bk-btn text-center">
            <div className="container">
              <div className="container">
                <span onClick={handleContinue}
                disabled={isButtonDisabled}
                // disabled={true}
                type="submit"
                className={`btn ${isButtonDisabled && "disabled"}`}
              >
                {isButtonDisabled && (
                  <img
                    src={Loading}
                    alt=""
                    style={{ width: "30px", paddingRight: "5px" }}
                  />
                )}
                  Continue with this deal
                </span>
              </div>
              <h4 className="mb-5 cp"
                onClick={() => navigate(`/orders/cancel/final-offer/${id}`)}
              >
                No, thank you, I don't want this great deal.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderCancel;
