const menus = {
  "Membership Options": [
    { name: "Cost Factor", path: "/cancel/membership" },
    { name: "Not Using MemberShip", path: "/cancel/membership" },
    { name: "Dont Want MemberShip", path: "/cancel/membership" },
  ],
  "Order Options": [
    { name: "Ordered Wrong Item", path: "/cancel/order" },
    { name: "Cost Factor", path: "/cancel/order" },
    { name: "Too Much Product", path: "/cancel/order" },
  ],
};

export default Object.freeze(menus);
