import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../context";
import { useCallback, useEffect, useState } from "react";
import DeleteSingleOrder from "../../../../components/DelSingleOrder";
import { isTokenValid } from "../../../../utils/isTokenValid";
import useImageFetch from "../../../../hooks/useImageFetch";
import useFetch from "../../../../hooks/useFetch";
import OrderService from "../../../../services/OrderService";
import DeleteOrderItem from "../../../../components/DelSingleOrder/deleteItem";
import { getRefundAmount } from "../../../../utils/getRefundAmount";
import { ToastContainer, toast } from "react-toastify";
import { isBrainFunnelCampaign } from "../../../../utils/isBrainfunnel";
import { capitalizeFirstLetter } from "../../../../utils/capitalize";
import Loading from "../../../../assets/images/loader.gif";


const FinalOffer = () => {
  const { id } = useParams();
  const { state, dispatch } = useAuth();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [referredProduct, setReferredProduct] = useState(null);
  const [revisedOrder, setRevisedOrder] = useState([]);
  const [prd, setPrd] = useState();
  const [discount, setDiscount] = useState(30);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const user =
    state?.user?.user_firstname ||
    JSON.parse(localStorage.getItem("user_firstname"));
  // const discount = 30;
  useEffect(() => {
    try {
      const token = JSON.stringify(localStorage.getItem("token"));
      if (!token || !isTokenValid(token)) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  const getSingleProduct = useCallback(() => {
    return OrderService.getSingleItem(id);
  }, [id]);

  useEffect(() => {
    const image = JSON.stringify(JSON.parse(localStorage.getItem("image")));
    const name = JSON.parse(JSON.stringify(localStorage.getItem("name")));
    const mainImage = `${localStorage.getItem("mainImage")}`;
    setProduct({ name, image,mainImage });
  }, []);

  const { data, isSuccess, error, isError, isLoading } =
    useFetch(getSingleProduct);
  const { imageData } = useImageFetch();

  useEffect(() => {
    const setProductDetail = (param) => {
      const prod = param.find((item) => {
        return item?.name === product?.name;
      });
      localStorage.qty = prod.product_qty;
      setProductDetails(prod);
    };
    if (!!data?.data?.products.length && product) {
      setProductDetail(data?.data?.products);
    }
    if (isBrainFunnelCampaign(data?.data?.campaign_id)) {
      setDiscount(75);
    }
  }, [data, id]);

  const handleSubmit = async (payload) => {
    try {
      const response = await OrderService.refundOrder(payload);
      if (response.status) {
        return navigate(`/orders/cancelled/${id}`);
      }
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsButtonDisabled(false);
    } catch (error) {
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsButtonDisabled(false);
    }
  };

  // const createNewOrder = () => {};
  // const deleteMultiple = async (idOne, idTwo) => {
  //   const newOrderItem = [idOne, idTwo].filter(
  //     (item) => item !== productDetails.product_id
  //   );
  //   try {
  //     const response = await OrderService.refundOrder(idOne);
  //     const response2 = await OrderService.refundOrder(idTwo);
  //     if (response && response2) {
  //       createNewOrder(newOrderItem);
  //     }
  //   } catch (error) {
  //     toast.error("Something Went Wrong!", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  // console.log("###PD: ", productDetails);
  const handleCancel = () => {
    // if (data?.data?.products.length > 1) {
    //   console.log("Hello got multiple products", data.data.products);
    //   const payloadone = {};
    //   const payloadtwo = {};
    //   return deleteMultiple(payloadone, payloadtwo);
    // const payload = {
    //   id,
    //   prod: productDetails.product_id,
    //   amount: data?.totals_breakdown.total - (data.amount_refunded_to_date || 0),
    // };
    // handleSubmit(payload);
    // }
    // console.log(
    //   "the order data: ",
    //   data?.data?.totals_breakdown.total - (data?.data?.totals_breakdown.amount_refunded || productDetails?.refund_amount),
    //   productDetails?.price,
    //   productDetails?.refund_amount,
    // );
    const payload = {
      id,
      prod: productDetails?.product_id,
      // amount:
      //   data?.data?.totals_breakdown.total -
      //   (data?.data?.totals_breakdown.amount_refunded ||
      //     productDetails?.refund_amount),
      amount: productDetails?.price - productDetails?.refund_amount,
    };
    handleSubmit(payload);
  };

  const handleContinue = () => {
    setIsButtonDisabled(true);
    const payload = {
      id,
      prod: productDetails.product_id,
      amount: getRefundAmount({
        price: productDetails?.price,
        discount,
      }),
    };
    handleSubmit(payload);
  };

  return (
    <>
      <ToastContainer />
      <div className="container pagePadding">
      <h1>Wait consider before you go!</h1>
      <h4 className="iv-sub-title">
        {user &&
          `${capitalizeFirstLetter(
            user
          )},  Keep your order and we'll issue you a ${discount}% `}
        refund.
      </h4>
      <div className="blue-line"></div>

      <div className="container">
        {/* <DeleteSingleOrder
          name={product?.name}
          img={imageData?.[product?.image]?.image}
          price={productDetails?.price}
        /> */}

        <DeleteOrderItem
          name={product?.name}
          // img={imageData?.[product?.image]?.image}
          img={product?.mainImage}
          price={productDetails?.price}
          about={`YES! Keep my order and process my $${getRefundAmount({
            price: productDetails?.price,
            discount,
          })} refund now`}
          discount={discount}
        />

        <div className="bk-btn text-center">
          <div className="container">
            <div className="container">
              <span className="btn" onClick={handleContinue}>
                Continue with this deal
              </span>
            </div>
            <span onClick={handleCancel}>
            <h4
              className="mb-5 cp"
              // className="btn"
              // onClick={handleCancel}
              // onClick={() => navigate(`/orders/cancelled/${id}`)}
            >
              No thanks, I’m declining this offer. I understand that this is a
              one-time exclusive offer and is not available anywhere outside of
              this page. By Clicking this link, I’m refusing to take this offer.
            </h4>
            </span>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default FinalOffer;
