import "./index.css";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../../assets/images/No-image.png";

const Benefits = ({ name, img, id, cancel = false }) => {
  const navigate = useNavigate();
  return (
    <section className="mb-sec pagePadding">
      <div className="container">
        <div className="row">
          <div className="mb-head">
            <h1>Membership benefits</h1>
          </div>
          <div className="mb-body">
            <div className="container">
              <div className="mb-content">
                <div className="row align-items-center">
                  <div className="col-md-4 col-12">
                    <div className="mb-left">
                      <img
                        src={img||DefaultImage}
                        alt="Product"
                        className="img-fluid"
                        onError={(e) => {
                          e.target.src = DefaultImage
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-8 col-12">
                    <div className="mb-right">
                      <div className="mr-inner">
                        As a <strong>{name}</strong> member you will receive
                        following benefits every single month.&nbsp;Be sure to
                        use this membership and take advantage of the following
                        benefits with your&nbsp;
                        <strong>{name}</strong> Today:
                        <ul>
                          <li>
                            <strong>FREE </strong>shipping on all orders!
                          </li>
                          <li>
                            <strong>80%</strong> <strong>OFF </strong>on all of
                            the great products.
                          </li>
                          <li>
                            No hassle, no questions asked replacement on any
                            items.
                          </li>
                          <li>
                            <strong>Priority processing</strong> – have your
                            orders placed in front of the line!
                          </li>
                          <li>
                            Get it all free for the first{" "}
                            <strong>30 days!</strong>
                          </li>
                        </ul>
                      </div>
                      <div className="mb-link">
                        <a
                          href="https://wholesalehealthclub.com/"
                          className="mb-link"
                          target="_blank"
                        >
                          <u>Click here to access the membership portal</u>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-con">
          {!cancel ? (
            <span
              className="btn"
              onClick={() => navigate(`/membership-benifit/complete/${id}`)}
            >
              Continue
            </span>
          ) : (
            <span
              className="btn"
              onClick={() => navigate(`/membership-benifit/complete/${id}`)}
            >
              Yes, Keep my membership
            </span>
          )}
          
        </div>
      </div>
    </section>
  );
};

export default Benefits;
