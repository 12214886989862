// @ts-nocheck
import { createContext, useContext, useReducer } from "react";

const AuthContext = createContext();

const initialState = {
  isAuthenticated: false,
  user: null,
  token: "",
  status: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem(
        "user_firstname",
        JSON.stringify(action?.payload?.first_name)
      );
      localStorage.setItem(
        "user_email",
        JSON.stringify(action?.payload?.email)
      );
      localStorage.setItem(
        "customer_id",
        JSON.stringify(action?.payload?.customer_id)
      );
      localStorage.setItem("status", JSON.stringify(1));
      localStorage.user_secret = JSON.stringify({secret: action?.payload.secret, time: new Date()});

      return {
        ...state,
        isAuthenticated: false,
        user: {
          user_firstname: action?.payload?.first_name,
          user_email: action?.payload?.email,
          customer_id: action.payload.customer_id,
        },
        token: action?.payload?.token,
        status: 1,
      };

    case "OTP_VERIFY":
      localStorage.setItem(
        "user_lastname",
        JSON.stringify(action?.payload?.last_name)
      );
      localStorage.setItem("status", JSON.stringify(2));
      localStorage.token = action?.payload?.token;
      // localStorage.setItem("token", JSON.stringify(action.payload.token));
      return {
        ...state,
        isAuthenticated: false,
        user: {
          user_firstname: action?.payload?.first_name,
          user_lastname: action?.payload?.last_name,
          user_email: action?.payload?.email,
        },
        token: action?.payload?.token,
        status: 2,
      };

    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: "",
        status: 0,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
