import { useEffect, useRef, useState } from "react";
import "./index.css";
import DefaultImage from "../../assets/images/No-image.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const DeleteSingleOrder = ({
  name,
  id,
  img,
  price,
  discount,
  prd,
  setPrd,
  about,
  similar = false,
  similarPrdImg
}) => {
  localStorage.setItem('product_image',similarPrdImg);
  const handleBorder = (data) => {
    // console.log("Param", dis);
    return data === id ? "1px solid black" : "";
  };
  


  return (
    <>
      <div className="container">
        <div
          className={`order-div ${!similar && "rev-order-div"}`}
          onClick={() => similar && setPrd(id)}
        >
          <div style={{ border: `${similar && handleBorder(prd)}` }}>
            <div className={`cmn-box`}>
              <div className="order-image">
                <img
                  //src={!similar ? img : similarPrdImg}
                  src={(!similar ? img : similarPrdImg) || DefaultImage}
                  className="img-fluid"
                  alt="Product"
                  onError={(e) => {
                    e.target.src = DefaultImage;
                  }}
                />
              </div>
              <div className="order-content">
                <div className="oc-div oc-div-01">
                  <div className="oc-div-left"></div>
                  <div className="oc-div-right"></div>
                </div>

                <div className="oc-div oc-div-02">
                  <div className="oc-div-left">
                    <h3 className={`${!similar && "Order-product-name"}`}>
                      {name}
                    </h3>
                    <h3 className={`${!similar && "order-product-price"}`}>
                      $ { price ? price : "0.00" }
                    </h3>
                  </div>
                  <div className="oc-div-right"></div>
                </div>

                <div className="oc-div oc-div-03">
                  <div className="oc-div-left">
                    <div className="oc-div-left">
                      <p>{discount}</p>
                    </div>
                  </div>
                  <div className="oc-div-right">
                    <h3 className="comp-order">
                      {similar ? "" : "Current Order"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeleteSingleOrder;
