import order_box from "../assets/images/order-icon.png";
import faq_box from "../assets/images/faq-icon-02.png";
import product_info from "../assets/images/product-info-icon.png";
import update_info from "../assets/images/update-info-icon.png";

const menus = [
  { path: "/orders", title: "My Orders", img: order_box },
  {
    path: "https://www.wholesalehealthclub.com/my-account-info",
    title: "Update Information",
    img: update_info,
  },
  {
    path: "https://www.wholesalehealthclub.com/shop/supplements",
    title: "Product Information",
    img: product_info,
  },
  { path: "/faq-items", title: "FAQ,s", img: faq_box },
];

export default Object.freeze(menus);

