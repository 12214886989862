import "./index.css";
import faqMenus from "../../defaultData/faq";
import { useCallback, useEffect, useState } from "react";
import useFaqFetch from "../../hooks/faqFetch";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FAQService from "../../services/FAQService";

const FaqHome = () => {
  const [searchText, setSearchText] = useState();
  const navigate = useNavigate();
  

  // const endPoint = "get-faq-category";

  const getFAQ = useCallback(() => {
    return FAQService.getItems();
  }, []);

  const { data, isError, isLoading, isSuccess } = useFaqFetch(getFAQ);

  useEffect(() => {
    if (data?.length) {
      data.forEach((element, idx) => {
        faqMenus[idx]["title"] = element?.faq_category_name;
        faqMenus[idx]["id"] = element?.id;
      });
    }
  }, [data]);

  if (isLoading) {
    return (

      <>
      <div className="pagePadding faq-shipping">
       <div className="iv-top text-center">
        <h2 className="iv-title">FAQs</h2>
        <h4 className="iv-sub-title">
        <Skeleton
            height={"40"}
            count={1}
            style={{ margin: "10px 10px" }}
            width={"300px"}
          />
        </h4>

        <Skeleton
            height={"20"}
            count={1}
            style={{ margin: "10px 10px" }}
            width={"60px"}
          />
        
      </div>

      <div style={{ display:"flex", justifyContent:"center"}}>
        {new Array(3).fill("").map((_item, idx) => (
          <Skeleton
            key={idx}
            height={"200px"}
            count={1}
            style={{ margin: "50px 10px" }}
            width={"250px"}
          />
        ))}
      </div>
      </div>
      </>

     
    );
  }

  if (isSuccess && !data?.length) {
    return <div className="pagePadding msgDisplaySec"><h1>Sorry No data found!</h1></div>;
  }

  if (isError) {
    return <div className="pagePadding msgDisplaySec"><h1>Something went wrong!</h1></div>;
  }

  return (
    <>
      <ToastContainer />
      {/* <div className="bread-nav">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Main Menu</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Topics
              </li>
            </ol>
          </nav>
        </div>
      </div> */}

      <div className="pagePadding">
        <div className="iv-top text-center">
          <h2 className="iv-title">FAQs</h2>
          <h4 className="iv-sub-title">
            Select a category below related to your question
          </h4>
          <div className="blue-line"></div>
        </div>

        <div className="home-icons">
          <div className="container">
            <div className="row justify-content-center">
              {faqMenus?.map((item, idx) => (
                <div
                  key={idx}
                  className="choice col-sm-auto col-12"
                  onClick={() => navigate(item?.path, { state: item?.id })}
                >
                  <div className="choice-box hide-on-mob">
                    <img src={item?.img} className="img-fluid" />
                    <h2 className="choice-name">{item?.title}</h2>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FaqHome;
