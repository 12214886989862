import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_VERSION}`,
  timeout: 50000,
});

instance.interceptors.request.use(function (config) {
  return {
    ...config,
    headers: {
      Authorization: `Bearer ${localStorage?.getItem("token") || ""}` || "",
    },
  };
});

const responseBody = (response) => response?.data;

const requests = {
  get: async (url) => instance.get(url).then(responseBody),
  post: async (url, body) => instance.post(url, body).then(responseBody),
  put: async (url, body) => instance.patch(url, body).then(responseBody),
  delete: async (url) => instance.delete(url).then(responseBody),
};

export default requests;
