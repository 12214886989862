const options = {
    2: [
      { name: "Order Status", path: "/orders/status" },
      { name: "Revise Order", path: "/orders/revised" },
      { name: "Cancel Order", path: "/cancel" },
    ],
    3: [
      { name: "Membership Status", path: "/orders/status" },
      { name: "Membership Benefit", path: "/membership-benifit" },
      { name: "Cancel Subscription", path: "/cancel" },
    ],
  };

  export default Object.freeze(options);